import clsx from 'clsx';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import type { ComponentType } from 'react';
import { ProductCard } from '../../../components/cards/product/ProductCard';
import type { ProductCardProps } from '../../../components/cards/product/ProductCard';
import { Button } from '../../../components/ui/button/Button';
import { ScrollSnapSlider } from '../../../components/utility/scrollSnapSlider/ScrollSnapSlider';

import styles from './CollectionListing.module.scss';

export interface CollectionListingProps {
  title?: string;
  message?: string;
  buttonText?: string;
  buttonLink?: string;
  newTab?: boolean;
  image: ImageProps;
  backgroundColor: string;
  products: ProductCardProps[];
  isLoading?: boolean;
  productCardComponent?: ComponentType<ProductCardProps>;
}

export const CollectionListing = ({
  title,
  message,
  buttonText,
  buttonLink,
  newTab,
  image,
  backgroundColor,
  products,
  isLoading = false,
  productCardComponent: ProductCardComponent = ProductCard,
}: CollectionListingProps) => {
  if (!products.length && !isLoading) return null;

  return (
    <section
      className={clsx(
        styles.collectionListing,
        'container',
        styles.collectionListing__carousel
      )}
    >
      <div className={styles.collectionListing__grid}>
        <div
          className={styles.collectionListing__message}
          style={{ backgroundColor }}
        >
          <Image
            alt={image?.alt}
            src={image?.src}
            title={image?.title || image?.alt}
            fill
            sizes="(min-width: 1024px) 534px, 100vw"
            className={styles.collectionListing__messageBackground}
          />

          <div className={styles.collectionListing__messageForeground}>
            {title && (
              <h2
                className="text-heading-h2-desktop text-heading-h2-mobile
"
              >
                {title}
              </h2>
            )}

            {message && (
              <p
                className={clsx(
                  styles.collectionListing__text,
                  'text-heading-h6-desktop text-heading-h6-mobile'
                )}
              >
                {message}
              </p>
            )}

            {buttonText && buttonLink && (
              <Button
                type="button"
                href={buttonLink}
                className={styles.collectionListing__button}
                target={newTab ? '_blank' : undefined}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </div>

        <div className={styles.collectionListing__sliderContainer}>
          {!isLoading && (
            <ScrollSnapSlider
              className={styles.collectionListing__slider}
              height={null}
              spacing={null}
            >
              {products.map((product, index) => {
                if (!product?.product) return null;

                return (
                  <article
                    style={{ height: '100%' }}
                    data-test-id="productCard"
                    key={index}
                  >
                    <ProductCardComponent
                      key={index}
                      className={styles.collectionListing__product}
                      {...product}
                      isLoading={false}
                    />
                  </article>
                );
              })}
            </ScrollSnapSlider>
          )}

          {isLoading && (
            <ScrollSnapSlider
              className={styles.collectionListing__slider}
              height={null}
              spacing={null}
            >
              {[...Array(4).keys()].map((key) => {
                return (
                  <article
                    style={{ height: '100%' }}
                    data-test-id="productCard"
                    key={key}
                  >
                    <ProductCardComponent
                      isLoading={true}
                      className={styles.collectionListing__product}
                    />
                  </article>
                );
              })}
            </ScrollSnapSlider>
          )}
        </div>
      </div>
    </section>
  );
};

export default CollectionListing;
