import type { CollectionListingProps } from '@we-make-websites/ui-lib';
import { CollectionListing } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { storeLocale } from '@/root/constants';
import { useProductCardQuery } from 'utils/hooks/useProductCardQuery';
import { ProductCardConnected } from '../productCard/ProductCardConnected';

export type CollectionListingContext = CollectionListingProps & {
  isLoading: boolean;
};

export interface CollectionListingWrapperProps
  extends Omit<CollectionListingProps, 'products'> {
  products: Array<{
    title: string;
    shopifyId: string;
    handle: string;
  }>;
  isLoading?: boolean;
}

export function CollectionListingWrapper(
  collectionListingProps: CollectionListingWrapperProps
) {
  const router = useRouter();
  const locale = storeLocale(router.locale || router.defaultLocale);
  const { products, isLoading } = useProductCardQuery(
    collectionListingProps.products,
    locale
  );

  // memoize the full context value
  const componentData = useMemo(() => {
    return {
      ...collectionListingProps,
      products,
      isLoading,
    };
  }, [collectionListingProps, products, isLoading]);

  return (
    <CollectionListing
      {...componentData}
      productCardComponent={ProductCardConnected}
    />
  );
}
